import * as React from "react"
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../components/ui/button/Button";
import projects from "../data/projects.json"
import about from "../data/about.json"
import { useKeenSlider } from "keen-slider/react";
import { FC } from "react";
import { TypeImage } from "../types";
import Image from "../components/ui/Image";
import { motion } from "framer-motion";
import { fadeLeft, fadeRight, fadeUp, hVariants, pVariants } from "../components/_animationVariants";
import t from "../i18n";
import HeadMeta from "../components/HeadMeta";

type TypeIndexPage = {
    pageContext: {
        locale: string
    }
}

const IndexPage: FC<TypeIndexPage> = ( { pageContext } ) => {
    const { locale: l } = pageContext
    const { intro, allFile: { projectImages } } = useStaticQuery<{ intro: TypeImage, allFile: { projectImages: TypeImage[] } }>( graphql`{
        intro: file(relativePath: {eq: "many-legs-6.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        },
        allFile( filter: {extension: {regex: "/jpeg|jpg|png|gif/"} } ) {
            projectImages: nodes {
                relativePath
                childImageSharp {
                    original {
                        src
                    }
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
    ` )
    return (
        <Layout noContainer noContentPadding>
            <HeadMeta title={ t( "Home" ) }/>
            <section className="hero min-h--main">
                <h1 className="hero__title px--xl mt--xxl lg:mt--0">
                    <motion.span variants={ fadeRight } className="hero__title__1 block text--bgDimmed">Objects of</motion.span>
                    <motion.span variants={ fadeUp } className="hero__title__2 text--darkGray block">Desire</motion.span>
                </h1>
                <motion.div variants={ pVariants } className="hero__about relative p--xl max-w--60ch mb--xxl lg:mb--0">
                    <p className={ "h5 text--textDimmed" }>
                        { about.intro[l] }
                    </p>
                    <motion.div variants={ hVariants }>
                        <Button look={ "line" } scrollTo={ "projects" } className={ "mt--lg" }>{ t( "View Projects" ) }</Button>
                    </motion.div>
                </motion.div>
                <motion.div variants={ fadeLeft } className={ "hero__img--wrapper md:h--100" }>
                    <Image
                        className={ "hero__img min-h--v50 h--100 w--100" }
                        data={ intro.childImageSharp.gatsbyImageData }
                        alt={ "Many legs sofa" }
                    />
                </motion.div>
            </section>
            <ProjectsSlider images={ projectImages }/>
        </Layout>
    )
}

export default IndexPage

type ProjectsSlider = {
    images: TypeImage[]
}

const ProjectsSlider: FC<ProjectsSlider> = ( { images } ) => {
    const [ sliderRef, slider ] = useKeenSlider<HTMLDivElement>( {
        slidesPerView: 5,
        spacing      : 32,
        centered     : true,
        breakpoints  : {
            '(min-width: 0rem) and (max-width: 48rem)'  : {
                slidesPerView: 1,
            },
            '(min-width: 48rem) and (max-width: 75rem)' : {
                slidesPerView: 3,
            },
            '(min-width: 75rem) and (max-width: 100rem)': {
                slidesPerView: 4,
            },
        }
    } )
    return (
        <section id={ "projects" } className={ "py--huge bg--darkGray px--lg" }>
            <div className={ "md:flex--center-middle flex--center mb--xxl relative" }>
                <h2 className={ "text--xl-1 text--body" }>Projects</h2>
                <div className={ "flex--center absolute right--0 top--auto" }>
                    <Button look={ "minimal" } color={ "bg" } onClick={ () => slider.prev() }>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 36 36">
                            <path d="M23.12 11.12L21 9l-9 9 9 9 2.12-2.12L16.24 18z"/>
                        </svg>
                    </Button>
                    <Button look={ "minimal" } color={ "bg" } onClick={ () => slider.next() }>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 36 36">
                            <path d="M15 9l-2.12 2.12L19.76 18l-6.88 6.88L15 27l9-9z"/>
                        </svg>
                    </Button>
                </div>
            </div>
            <div ref={ sliderRef } className={ "keen-slider projects-summary" }>
                { projects.map( project => {
                    const ftImage = images.find( img => img.relativePath === `projects/${ project.slug }/${ project.featured_img }.jpg` )
                    return (
                        <div key={ project.id } className={ "keen-slider__slide gallery__item " }>
                            <Button to={ `/projects/${ project.slug }` } look={ "empty" } className={ "relative w--100 flex" }>
                                <Image data={ ftImage.childImageSharp.gatsbyImageData } alt={ project.name } className={ "h--thumb" }/>
                                <div className={ "gallery__item__overlay flex--center-middle absolute inset--0 bg--overlay" }/>
                            </Button>
                            <Button look={ "empty" } className={ "w--100 flex text--gray-1 p--md" } to={ `/projects/${ project.slug }` }>
                                <h3>{ project.name }</h3>
                            </Button>
                        </div>
                    )
                } ) }
            </div>
        </section>
    )
}
